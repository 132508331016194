import React, { CSSProperties, FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import Anchor from 'evcharging/components/Anchor';
import clsx from 'clsx';

type Variant = 'h1' | 'h2' | 'p';

interface TitleProps extends CSSProperties {
  children: ReactNode;
  color?: 'dark' | 'middle' | 'light';
  center?: boolean;
  highlight?: boolean;
  light?: boolean;
  id?: string;
  fontSize?: number;
  maxWidth?: number | string;
  variant?: Variant;
}

const createComponent = (variant: 'h1' | 'h2' | 'p', props = {}) => {
  const { children, ...rest } = props as { children: ReactNode };
  if (variant === 'h1') {
    return <h1 {...rest}>{children}</h1>;
  }
  if (variant === 'h2') {
    return <h2 {...rest}>{children}</h2>;
  }
  if (variant === 'p') {
    return <p {...rest}>{children}</p>;
  }
};

const Title: FC<TitleProps> = (props) => {
  const theme = useTheme();
  const {
    children,
    center = false,
    highlight = false,
    light = false,
    id,
    variant = 'h1',
    ...cssProps
  } = props;
  const styles = createStyles(theme, props);
  const componentProps = {
    className: clsx(
      styles.root,
      center && styles.center,
      highlight && styles.highlight,
      light && styles.light,
      css({ ...cssProps })
    ),
    children,
  };

  return (
    <>
      {id && <Anchor id={id} />}
      {createComponent(variant, componentProps)}
    </>
  );
};

const createStyles = (
  theme: Theme,
  { color = 'dark', fontSize = 34, maxWidth = 800 }: TitleProps
) => ({
  root: css`
    max-width: ${typeof maxWidth === 'string' ? maxWidth : maxWidth + 'px'};
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: ${theme.colors.text[color]};
    ${theme.breakpoints.mobile.up} {
      font-size: ${fontSize}px;
    }
  `,
  center: css`
    margin: 0 auto;
    text-align: center;
  `,
  highlight: css`
    color: ${theme.colors.accent};
  `,
  light: css`
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  `,
});

export default Title;
