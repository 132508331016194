import React, { ChangeEvent, FC, TextareaHTMLAttributes } from 'react';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import { Theme } from 'evcharging/app/themes';
import InputLabel from 'evcharging/components/InputLabel';

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onChangeValue?: (value: string) => void;
  invalid?: boolean;
  label?: string;
  tooltip?: string;
  grey?: boolean;
  smallLabel?: boolean;
};

const Textarea: FC<TextareaProps> = (props) => {
  const {
    onChangeValue,
    label = '',
    children,
    tooltip,
    invalid,
    grey,
    required,
    smallLabel = false,
    ...rest
  } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { invalid, grey });

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    onChangeValue && onChangeValue(event.target.value);

  return (
    <div className={styles.root}>
      {label && (
        <InputLabel label={label} required={required} tooltip={tooltip} small={smallLabel} />
      )}
      <textarea className={styles.textarea} {...rest} onChange={onChange} rows={3} />
      {children}
    </div>
  );
};

const createStyles = (theme: Theme, { invalid = false, grey = false }: Partial<TextareaProps>) => ({
  root: css`
    position: relative;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
  `,
  textarea: css`
    display: block;
    width: 100%;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background: #ffffff;
    border: 1px solid #acafb4;
    margin-bottom: 24px;
    border-radius: 5px;
    padding: 1rem;
    color: #000;
    outline: none;
    border: 1px solid ${invalid ? '#eb5757' : '#ebebeb'};
    ${grey && 'background: #f6f8f9;'}
    resize: none;

    :focus {
      border: 1px solid ${theme.colors.accent};
    }
  `,
});

export default Textarea;
