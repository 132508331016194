import {
  isEmpty,
  isRequired,
  isRequiredContacts,
} from 'evcharging/app/validation/validators';

const emptyCheckers: Validator[] = [isRequired, isRequiredContacts];

export type Validator = (
  value: any,
  values?: Record<string, any>,
) => boolean | Promise<boolean>;

const validateValue = async (
  value: unknown,
  validators: Validator[],
  values?: Record<string, any>,
): Promise<string> => {
  for (const validator of validators) {
    if (isEmpty(value) && !emptyCheckers.includes(validator)) {
      continue;
    }
    const isValid = await validator(value, values);
    if (!isValid) {
      return validator.name;
    }
  }
  return '';
};

export const validateObject = async (
  values: Record<string, any>,
  validators: Record<string, Validator[]>,
): Promise<{
  isValid: boolean;
  errors: Record<string, string>;
}> => {
  for (const [name, functions] of Object.entries(validators)) {
    const code = await validateValue(values[name], functions, values);
    if (code) {
      return { isValid: false, errors: { [name]: code } };
    }
  }
  return { isValid: true, errors: {} };
};
