import React, { FC } from 'react';
import { Theme } from 'evcharging/app/themes';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';

interface AnchorProps {
  id: string;
  top?: number;
}

const Anchor: FC<AnchorProps> = (props) => {
  const theme = useTheme();
  const styles = createStyles(theme, props);
  const { id } = props;
  return <div className={styles.root} id={id} />;
};

const createStyles = (theme: Theme, { top = -150 }: AnchorProps) => ({
  root: css`
    display: block;
    position: relative;
    visibility: hidden;
    top: ${top}px;
  `,
});

export default Anchor;
