import React, { FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import { Theme } from 'evcharging/app/themes';
import { CSSProperties } from '@emotion/serialize';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

interface ResponsibleProps extends CSSProperties {
  children: ReactNode;
  fractions: number[];
  space?: number;
  mobileCenter?: boolean;
  mobileReverse?: boolean;
}

const Responsible: FC<ResponsibleProps> = (props) => {
  const { children, fractions, space, mobileReverse, mobileCenter, ...cssProps } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { fractions, space, mobileReverse, mobileCenter });

  return <div className={clsx(styles.root, css({ ...cssProps }))}>{children}</div>;
};

const createStyles = (
  theme: Theme,
  { fractions, space = 20, mobileCenter = false, mobileReverse = false }: Partial<ResponsibleProps>
) => ({
  root: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${fractions
      .map<string>((fraction, index) => `& > *:nth-child(${index + 1}) {width: ${fraction}%;}`)
      .join('')}
    & img {
      width: 100%;
      height: auto;
    }
    ${theme.breakpoints.mobile.down} {
      ${mobileCenter &&
      `
        align-items: center;
        text-align: center;
      `}
      flex-direction: ${mobileReverse ? 'column-reverse' : 'column'};
      ${fractions
        .map<string>(
          (fraction, index) =>
            `& > *:nth-child(${index + 1}) {width: 100%; margin-bottom: ${space}px;}`
        )
        .join('')}
    }
  `,
});

export default Responsible;
