import { HttpMethod, HttpStatus } from 'evcharging/app/http/index';
import {
  InternalServerException,
  UnprocessableEntityException,
} from 'evcharging/app/http/exceptions';
import { validateObject, Validator } from 'evcharging/app/validation';

type Validators = Record<string, Validator[]>;
type Payload = Record<string, any>;

class Client {
  private method: HttpMethod = HttpMethod.GET;
  private body: Payload = {};
  private query: Payload = {};
  private validators: Validators = {};

  setValidators = (validators: Validators) => {
    this.validators = validators;
    return this;
  };

  setQuery = (query: Payload) => {
    this.query = query;
    return this;
  };

  setBody = (body: Payload) => {
    this.body = { ...body };
    return this;
  };

  setMethod = (method: HttpMethod) => {
    this.method = method;
    return this;
  };

  get = (url: string, init: RequestInit = {}) =>
    this.setMethod(HttpMethod.GET).send(url, init);

  post = (url: string, init: RequestInit = {}) =>
    this.setMethod(HttpMethod.POST).send(url, init);

  put = (url: string, init: RequestInit = {}) =>
    this.setMethod(HttpMethod.PUT).send(url, init);

  delete = (url: string, init: RequestInit = {}) =>
    this.setMethod(HttpMethod.DELETE).send(url, init);

  send = async (url: string, init: RequestInit = {}) => {
    await this.beforeSend();
    const searchParams = Object.entries(this.query).reduce<URLSearchParams>(
      (accum, entry) => {
        accum.set(...entry);
        return accum;
      },
      new URLSearchParams(),
    );
    const queryString = searchParams.toString();
    if (queryString) {
      url = `${url}?${queryString}`;
    }
    const response = await fetch(url, {
      ...init,
      method: this.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body:
        Object.keys(this.body).length > 0 ? JSON.stringify(this.body) : null,
    });
    return afterSend(response);
  };

  private beforeSend = async () => {
    const payload = { ...this.query, ...this.body };
    const { isValid, errors } = await validateObject(payload, this.validators);
    if (!isValid) {
      throw new UnprocessableEntityException(errors);
    }
  };
}

export const afterSend = async (response: Response) => {
  if (response.status === HttpStatus.NO_CONTENT) {
    return;
  }
  const json = await response.json();

  if (response.ok) {
    return json;
  }
  if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
    throw new UnprocessableEntityException(json);
  }
  throw new InternalServerException(json);
};

export default Client;
